.stepper {
  --s-stepper-bullet: 2rem;
  --s-stepper-bullet-half: calc(var(--s-stepper-bullet) / 2);
  --step-transition: background 0.5s, color 0.5s;
  --step-content: "✔︎";
  --step-color: hsl(0, 0%, 70%);
  --step-bar-bg: #37ad47;
  --step-bullet-bg: var(--step-bar-bg);
  --step-bullet-color: white;
  counter-reset: current-step;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}
.stepper::before {
    content: "";
    position: absolute;
    width: 100%;
    height: calc(var(--s-stepper-bullet-half) / 2);
    background: #d5d7db;
    -webkit-transition: var(--step-transition);
    transition: var(--step-transition);
    left: 0;
    top: 29px;
    opacity: .4;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: -1;
    border-radius: 12px;
}
.stepper__input {
  counter-increment: steps;
  display: none;
}
.stepper__step {
  counter-increment: current-step;
}
.stepper__input:checked ~ .stepper__step {
  --step-color: #d5d7db;
  --step-bar-bg: #d5d7db;
  --step-bullet-bg: var(--step-bar-bg);
  --step-bullet-color: #d5d7db;
  --step-content: counter(current-step);
}
.stepper__input:checked + .stepper__step {
  --step-bullet-bg: #37ad47;
  --step-bullet-color: white;
  --step-color: white;
}
.stepper__input:checked + .stepper__step .stepper__button::before {
  box-shadow: 0 0 0 2px #7dec8c;
}
.stepper__button {
  position: relative;
  text-align: center;
  color: var(--step-bullet-bg);
  display: block;
  font-weight: 900;
  font-size: 12px;
  margin-bottom: 0;
//   cursor: pointer;
}
.stepper__button::before {
  content: var(--step-content);
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin: 0 auto var(--s-stepper-bullet-half);
  height: var(--s-stepper-bullet);
  width: var(--s-stepper-bullet);
  border-radius: var(--s-stepper-bullet);
  -webkit-transition: var(--step-transition);
  transition: var(--step-transition);
  background: var(--step-bullet-bg);
  color: white;
}
.stepper__button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: calc(var(--s-stepper-bullet-half) / 2);
  background: var(--step-bar-bg);
  -webkit-transition: var(--step-transition);
  transition: var(--step-transition);
  top: var(--s-stepper-bullet-half);
  left: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  z-index: -1;
}
.stepper__step:last-child .stepper__button::after {
    display: none;
}
.stepper--flexbox {
  display: -webkit-box;
  display: flex;
}
.stepper--flexbox .stepper__step {
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}

//
// Chamasoft Login
// Pages SASS files are compiled into separate css files
//

// Initialization of global variables, mixins and functions
@import "../../init";

.login.login-1.chamasoft-login {
  // Form modes
  .login-signin,
  .login-signup,
  .login-forgot {
    display: none;
  }

  &.login-signin-on {
    .login-signup {
      display: none;
    }

    .login-signin {
      display: block;
    }

    .login-forgot {
      display: none;
    }
  }

  &.login-signup-on {
    .login-signup {
      display: block;
    }

    .login-signin {
      display: none;
    }

    .login-forgot {
      display: none;
    }
  }

  &.login-forgot-on {
    .login-signup {
      display: none;
    }

    .login-signin {
      display: none;
    }

    .login-forgot {
      display: block;
    }
  }
  .chamasoft-effect {
    height: 100%;
    width: 100px;
    position: absolute;
    top: 0;
    left: -100px;
  }
}

// Desktop view
@include media-breakpoint-up(lg) {
  .login.login-1.chamasoft-login {
    .login-aside {
      width: 100%;
      max-width: 800px;
    }

    .login-form {
      width: 100%;
      max-width: 420px;
    }

    .login-form.bank-options {
      width: 100%;
      max-width: 540px;
      margin-left: 180px;
    }
    ._full-layout {
      margin-left: 180px;
    }
  }
  .login.login-2.chamasoft-login {
    .login-form {
      width: 100%;
      max-width: 500px;
    }
  }
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
  .login.login-1.chamasoft-login {
    .login-aside {
      width: 100%;
      max-width: 800px;
    }
  }
}

// Tablet mode
@include media-breakpoint-down(md) {
  .login.login-1.chamasoft-login {
    .login-form {
      width: 100%;
      max-width: 350px;
    }
  }
}

// Mobile mode
@include media-breakpoint-down(xs) {
  .login.login-1.chamasoft-login {
    .login-form {
      width: 100%;
      max-width: 100%;
    }
  }
}

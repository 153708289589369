.resources #resource_img {
  position: absolute;
  margin-top: -4px;
  opacity: 0.7;
  font-size: 24px;
}
.resources.hoverable li {
  cursor: pointer;
  opacity: 1;
  color: #525a63;
  transition: all 0.3s ease;
}
.resources.hoverable li:hover {
  opacity: 0.9;
  color: #007bff;
  background-color: #eef3f841 !important;
  transition: all 0.3s ease;
}
.resources.hoverable li ._group {
  float: left;
  width: 100%;
  padding-left: 40px;
}
.resources.hoverable li ._role {
  float: left;
  width: 100%;
  padding-left: 40px;
  opacity: 0.7;
}
.resources.hoverable li ._indico {
  font-size: 20px;
  opacity: 0;
  color: #95b3d4;
  transition: all 0.3s ease;
}
.resources.hoverable li:hover > ._indico {
  opacity: 0.6;
  /* color: #007bff; */
  transition: all 0.3s ease;
}
/* ._form {
    display: none;
} */
._selected-resource {
  background-color: #eef3f8 !important;
}
.container {
  max-width: 960px;
}

.border-top {
  border-top: 1px solid #e5e5e5;
}
.border-bottom {
  border-bottom: 1px solid #e5e5e5;
}
.border-top-gray {
  border-top-color: #adb5bd;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.lh-condensed {
  line-height: 1.25;
}

body {
  background-image: linear-gradient(75deg, #ffffff, #f6fafd);
}
img {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}
._title {
  font-weight: 200;
  font-size: 22px;
  color: #666666;
}
._title span {
  font-weight: 500;
}
._title-descr {
  font-size: 12px;
  color: #666666;
}
.btn-pill {
  border-radius: 100px;
}
.dropdown-toggle::after {
  display: none;
}
._user button {
  min-width: 120px;
}
/* ._user button.btn-primary {
    color: #fff;
    background-color: #95b3d4;
    border-color: #95b3d4;
} */
._user ._initials {
  background: #ffffff52;
  border-radius: 120px;
  width: 36px;
  height: 36px;
  margin-top: 3px;
  left: 3px;
  position: absolute;
}
._user ._initials span {
  color: #ffffffaf;
  font-weight: 700;
  font-size: 12px;
}
._user ._name {
  color: #ffffff;
  line-height: 1;
  margin-top: 8px;
  margin-bottom: 9px;
  margin-left: 45px;
  float: left;
  margin-right: 12px;
}
._user ._name small {
  color: #ffffffbd;
}
.dropdown-menu {
  border: 0px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 2rem rgba(0, 44, 90, 0.2);
  margin-top: 10px;
  min-width: 14rem;
}
.dropdown-menu .dropdown-item {
  font-size: 14px;
}
.dropdown-menu .dropdown-item i.mdi {
  font-size: 16px;
}
